<template>
  <div>
    <t-modal :header="$i18n.t('likes_view' + (type == 'post' ? '' : '2'))" :value="modal" @closed="$emit('closeModal')"
      variant="lg">
      <div class="w-full" style="max-height: 50vh; overflow-y: auto">
        <div v-if="loader" class="mx-auto text-center">
          <t-loader extraClass="border-orange-primary" />
        </div>
        <div class="my-auto" v-if="arrayUsers.length === 0 && !loader">
          <p class="text-black dark:text-white text-center">
            {{ $i18n.t("no_elements") }}
          </p>
        </div>
        <div v-else class="my-1 px-3 w-full" v-for="(follow_user, index) in arrayUsers"
          :key="'follow-user-' + follow_user.id">
          <div class="w-full flex justify-between items-center">
            <div class="flex w-full cursor-pointer py-2">
              <div class="w-10 h-10" style="cursor: pointer" @click="$emit('openProfile', follow_user.user)">
                <div class="w-10 h-10">
                  <profile-picture :data="follow_user.user" :sizePx="128" />
                </div>
              </div>
              <div class="ml-3 my-auto" style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  cursor: pointer;
                  width: 100%;
                ">
                <Username :user="follow_user.user" />
                <div class="my-auto w-1/3">
                  <t-button v-if="loggedUser && follow_user.user.id !== loggedUser.id" variant="empty"
                    @click="followListUser(follow_user, index)" class="mx-auto my-auto"
                    :class="followButtonClasses(follow_user)" style="height: fit-content"
                    :disabled="listFollowLoading === index">
                    <span :class="followTextClasses(follow_user)">{{ follow_user.followed_by_you_status === 0 || follow_user.user.followed_by_you_status === 0
                        ?
                        $i18n.t("follow")
                        : ""
                    }}{{ follow_user.followed_by_you_status === 1 || follow_user.user.followed_by_you_status === 1 ? $i18n.t("pending") : ""
}}{{
    follow_user.followed_by_you_status === 2 || follow_user.user.followed_by_you_status === 2
      ? $i18n.t("stop_following")
      : ""
}}{{
    follow_user.followed_by_you_status === 3 || follow_user.user.followed_by_you_status === 3 ? $i18n.t("rejected") : ""
}}</span>
                  </t-button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </t-modal>
  </div>
</template>

<script>
import ProfilePicture from "@/components/user/ProfilePicture.vue"
import Username from "@/components/user/Username.vue"
const notify = () => import("@/utils/notify.js")
export default {
  components: {
    ProfilePicture,
    Username
  },
  props: {
    modal: {
      type: Boolean,
      default: false
    },
    postID: {
      type: Number,
      required: true
    },
    type: {
      type: String,
      default: 'post'
    }
  },
  data() {
    return {
      arrayUsers: [],
      userSelected: null,
      loader: false,
      listFollowLoading: false,
    }
  },
  computed: {
    loggedUser() {
      return this.$store.getters["auth/getUser"];
    },
  },
  methods: {
    async openFollowModal(type) {
      this.arrayUsers = []
      this.loader = true
      this.followModal = true
      let params = {
        order_key: "updated_at",
        order_value: "desc",
        active: 1
      }
      if (this.type === 'post') {
        this.$store
          .dispatch("global/getItems", {
            route: `/like/all?type_child=post&id_child=${this.postID}&order_key=updated_at&order_value=desc`,
            module: null,
            noPaginate: true,
            params
          })
          .then((response) => {
            this.arrayUsers = response.data

            this.loader = false
          })
          .catch((error) => {
            this.loader = false
            notify().then(({ notification }) => {
              notification("error", error.code, this.$snotify, this.$i18n.locale)
            })
          })
      }
      if (this.type === 'comment') {
        this.$store
          .dispatch("global/getItems", {
            route: `/like/all?type_child=comment&id_real_child=${this.postID}&order_key=updated_at&order_value=desc`,
            module: null,
            noPaginate: true,
            params
          })
          .then((response) => {
            this.arrayUsers = response.data

            this.loader = false
          })
          .catch((error) => {
            this.loader = false
            notify().then(({ notification }) => {
              notification("error", error.code, this.$snotify, this.$i18n.locale)
            })
          })
      }

    },
    followButtonClasses(follow_user) {
      if (!follow_user) {
        return "px-2 py-1 bg-transparent border border-primary-500 rounded shadow-sm hover:bg-primary-1200";
      }

      if (follow_user.followed_by_you_status === 0 || follow_user.user.followed_by_you_status === 0)
        return "px-2 py-1 bg-primary-1100 border border-primary-500 rounded shadow-sm hover:bg-primary-1200";
      else if (follow_user.followed_by_you_status === 1 || follow_user.user.followed_by_you_status === 1)
        return "px-2 py-1 bg-transparent border border-gray-500 rounded shadow-sm hover:bg-gray-500";
      else if (follow_user.followed_by_you_status === 2 || follow_user.user.followed_by_you_status === 2)
        return "px-2 py-1 bg-red-500 border border-red-500 rounded shadow-sm hover:bg-red-500";
      else
        return "px-2 py-1 bg-primary-1100 border border-primary-500 rounded shadow-sm hover:bg-primary-1200";
    },
    followTextClasses(follow_user) {
      if (!follow_user.user) {
        return "text-sm hover:text-white";
      }
      if ([0,2,3].includes(follow_user.followed_by_you_status) || [0, 2, 3].includes(follow_user.user.followed_by_you_status))
        return "text-sm text-white";
      if (follow_user.followed_by_you_status === 1 || follow_user.user.followed_by_you_status === 1) return "text-sm text-white";
    },
    followListUser(follow_user, index) {
      if (follow_user.followed_by_you_status === 3 || follow_user.user.followed_by_you_status === 3) return;
      this.listFollowLoading = index;
      this.$store
        .dispatch("global/create", {
          payload: {
            id_user_sent: this.loggedUser.id,
            id_user_received: follow_user.id_user,
          },
          route: "/follow_request/save",
          module: null,
        })
        .then((response) => {
          let userx = follow_user;
          if (response.data.active === 2) {
            userx.followed_by_you_status = 0;
            userx.user.followed_by_you_status = 0;
          } else {
            userx.followed_by_you_status = response.data.status_request;
            userx.user.followed_by_you_status = response.data.status_request;
          }

          this.arrayUsers[index].followed_by_you_status = userx.followed_by_you_status;
          this.arrayUsers[index].user.followed_by_you_status = userx.followed_by_you_status;
          this.listFollowLoading = null;
        })
        .catch((error) => {
          this.listFollowLoading = null;
          console.log("list follow error:", error);
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale);
          });
        });
    },
  },
  watch: {
    modal(val) {
      if (val === true) {
        this.openFollowModal()
      }
    }
  }
}
</script>

<style>

</style>
