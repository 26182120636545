<template>
  <div>
    <div class="mt-1 px-5 flex justify-between">
      <div class="flex">
        <img :src="
          post.liked === 0
            ? '/static/icons/jevi.svg'
            : '/static/icons/jeviFilled.svg'
        " alt="Jevi button" width="25px" class="mr-2 my-auto" :class="post.liked === 0 ? 'dark:filter invert' : ''"
          :style="allowLike && !noAuth ? 'cursor: pointer' : ''"
          @click="allowLike && !noAuth ? likePost() : null" />

      <img src="/static/icons/comment.svg" alt="Comments button" width="25px"
          class="ml-2 mr-2 my-auto dark:filter invert" :class="allowComments && !noAuth ? 'cursor-pointer' : ''"
          @click="allowComments && !noAuth ? $emit('toggleComments') : null" />

        <img v-if="post.type === 2 || post.type === 5" src="/static/icons/views.svg" alt="Views icon" width="25px"
          class="ml-2 mr-2 my-auto dark:filter invert" style="cursor: default" />

        <p class="my-auto text-black dark:text-white text-sm sm:text-md" v-if="post.type === 2 || post.type === 5">
          {{ calculateHighNumber(post.total_views) }}
        </p>

        <img v-if="post.type === 2 || post.type === 5" src="/static/icons/share.svg" alt="Share icon" width="25px"
          class="ml-2 my-auto dark:filter invert" :style="!noAuth ? 'cursor: pointer' : ''"
          @click="!noAuth ? $emit('sharePost', element) : null" />
      </div>

      <div>
        <t-button v-if="
          !noAuth &&
          allowSubscription &&
          element.type === 2 &&
          OAuthPreferences &&
          OAuthPreferences.subscriptions &&
          element.subscribed_to_channel == 0
        " type="button" class="ml-2 my-auto" @click="$emit('subscribeChannel', element)">
          <div>
            <t-loader v-if="isLoadingSubscription" extraClass="border-orange-primary" />
            <p class="text-sm py-0 my-0" v-else>{{ $i18n.t("subscribe") }}</p>
          </div>
        </t-button>

        <t-button v-if="
          !noAuth &&
          allowSubscription &&
          element.type === 2 &&
          OAuthPreferences &&
          OAuthPreferences.subscriptions &&
          element.subscribed_to_channel == 1
        " type="button" class="ml-2 py-1 my-auto disabled" style="cursor: default;">
          <div>
            <p class="text-sm py-0 my-0">{{ $i18n.t("subscribed") }}</p>
          </div>
        </t-button>
      </div>
    </div>

    <div class="mt-1 w-full flex justify-start">
      <Likes :element="post" :id="loggedUser ? loggedUser.id : null" @openModal="$emit('openModal', post.id)"
        @openProfile="(user) => $emit('openProfile', user)" />
    </div>
  </div>
</template>

<script>

import Likes from "@/components/post/likes.vue"

export default {
  components: {
    Likes
  },
  data() {
    return {
      post: null,
    }
  },
  props: {
    element: {
      type: Object,
      required: true
    },
    noAuth: {
      type: Boolean,
      default: false
    },
    OAuthPreferences: {
      type: Object,
      default: null
    },
    allowLike: {
      type: Boolean,
      default: false
    },
    calculateHighNumber: {
      type: Function
    },
    allowComments: {
      type: Boolean,
      default: false
    },
    allowSubscription: {
      type: Boolean,
      default: true
    },
    isLoadingSubscription: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      required: true
    },
  },
  emits: ['toggleComments','openModal','openProfile'],
  computed: {
    loggedUser() {
      return this.$store.getters['auth/getUser'];
    }
  },
  created() {
    this.post = this.element;
  },
  methods: {
    likePost() {
      this.post.liked = this.post.liked === 1 ? 0 : 1

      if (this.post.liked === 1) {
        this.post.total_likes++
        this.post.likes.push({
          id: this.post.id + "-0",
          id_user: this.loggedUser.id,
          id_child: this.post.id,
          id_real_child: this.post.id,
          type_child: "post",
          user: this.loggedUser,
          active: 1,
          created_at: "2023-02-08 12:06:33",
          updated_at: "2023-02-08 12:58:48",          
        })
      } else {
        this.post.total_likes--

        let index = this.post.likes.findIndex(x => x.id_user === this.loggedUser.id)

        if (index > -1) this.post.likes.splice(index, 1)
      }

      this.$emit("likePost", this.element)
    }
  }
}
</script>

<style>

</style>
