<template>
    <div @click="openProfile(user)" class="text-black dark:text-white mr-2" style="display: flex; align-items: center">
        <div class="flex justify-between ">
            <strong>{{ user.username }}</strong>
        </div>
        <span class="ml-1" v-if="user.verified == 1">
            <img src="/static/user_verified.png" style="display: flex; height: 16px; width: 16px" />
            <!--<font-awesome-icon class="mt-2 mb-0 my-0" icon="check-circle" style="color: #6a71ec; font-size: 16px" />-->
        </span>
    </div>
</template>

<script>
export default {
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    computed: {
        loggedUser() {
            return this.$store.getters["auth/getUser"];
        },
    },
    methods: {
        openProfile(user) {

            if (this.loggedUser && this.loggedUser.id === user.id) {
                this.$router.push("/profile");
            } else {
                this.$router.push(`/user/${user.username}`);
            }
        },
    }
}
</script>