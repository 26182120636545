var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mt-1 px-5 flex justify-between"},[_c('div',{staticClass:"flex"},[_c('img',{staticClass:"mr-2 my-auto",class:_vm.post.liked === 0 ? 'dark:filter invert' : '',style:(_vm.allowLike && !_vm.noAuth ? 'cursor: pointer' : ''),attrs:{"src":_vm.post.liked === 0
          ? '/static/icons/jevi.svg'
          : '/static/icons/jeviFilled.svg',"alt":"Jevi button","width":"25px"},on:{"click":function($event){_vm.allowLike && !_vm.noAuth ? _vm.likePost() : null}}}),_c('img',{staticClass:"ml-2 mr-2 my-auto dark:filter invert",class:_vm.allowComments && !_vm.noAuth ? 'cursor-pointer' : '',attrs:{"src":"/static/icons/comment.svg","alt":"Comments button","width":"25px"},on:{"click":function($event){_vm.allowComments && !_vm.noAuth ? _vm.$emit('toggleComments') : null}}}),(_vm.post.type === 2 || _vm.post.type === 5)?_c('img',{staticClass:"ml-2 mr-2 my-auto dark:filter invert",staticStyle:{"cursor":"default"},attrs:{"src":"/static/icons/views.svg","alt":"Views icon","width":"25px"}}):_vm._e(),(_vm.post.type === 2 || _vm.post.type === 5)?_c('p',{staticClass:"my-auto text-black dark:text-white text-sm sm:text-md"},[_vm._v(" "+_vm._s(_vm.calculateHighNumber(_vm.post.total_views))+" ")]):_vm._e(),(_vm.post.type === 2 || _vm.post.type === 5)?_c('img',{staticClass:"ml-2 my-auto dark:filter invert",style:(!_vm.noAuth ? 'cursor: pointer' : ''),attrs:{"src":"/static/icons/share.svg","alt":"Share icon","width":"25px"},on:{"click":function($event){!_vm.noAuth ? _vm.$emit('sharePost', _vm.element) : null}}}):_vm._e()]),_c('div',[(
        !_vm.noAuth &&
        _vm.allowSubscription &&
        _vm.element.type === 2 &&
        _vm.OAuthPreferences &&
        _vm.OAuthPreferences.subscriptions &&
        _vm.element.subscribed_to_channel == 0
      )?_c('t-button',{staticClass:"ml-2 my-auto",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('subscribeChannel', _vm.element)}}},[_c('div',[(_vm.isLoadingSubscription)?_c('t-loader',{attrs:{"extraClass":"border-orange-primary"}}):_c('p',{staticClass:"text-sm py-0 my-0"},[_vm._v(_vm._s(_vm.$i18n.t("subscribe")))])],1)]):_vm._e(),(
        !_vm.noAuth &&
        _vm.allowSubscription &&
        _vm.element.type === 2 &&
        _vm.OAuthPreferences &&
        _vm.OAuthPreferences.subscriptions &&
        _vm.element.subscribed_to_channel == 1
      )?_c('t-button',{staticClass:"ml-2 py-1 my-auto disabled",staticStyle:{"cursor":"default"},attrs:{"type":"button"}},[_c('div',[_c('p',{staticClass:"text-sm py-0 my-0"},[_vm._v(_vm._s(_vm.$i18n.t("subscribed")))])])]):_vm._e()],1)]),_c('div',{staticClass:"mt-1 w-full flex justify-start"},[_c('Likes',{attrs:{"element":_vm.post,"id":_vm.loggedUser ? _vm.loggedUser.id : null},on:{"openModal":function($event){return _vm.$emit('openModal', _vm.post.id)},"openProfile":function (user) { return _vm.$emit('openProfile', user); }}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }