<template>

  <div class="mt-1 px-5 mb-3 flex justify-start">


    <template v-if="element.likes.length === 1 && id && element.likes[0].id_user === id">
      <p class="text-black-500 text-sm dark:text-gray-400">
        {{ $i18n.t("only_liked_by_user") }}
      </p>
    </template>
    
    <template v-else-if="element.likes.length === 1 && !(id && element.likes[0].id_user === id)">
      <div style="min-height: 20px; min-width: 7%;" class="w-8 relative">
        <div class="w-5 absolute top-0" style="max-height: 20px; min-width: 20px"
          :class="[formatLeftImg[0]]" :style="{ 'z-index': formatZIndex[0] }">
          <img class=" object-cover rounded-full"
            :src="element.likes[0].user.imgkit_profile_picture ? element.likes[0].user.imgkit_profile_picture : '/static/profile-default.jpg'" />
        </div>
      </div>

      <p class="text-black-500 text-indigo-900 text-sm dark:text-white">
        <span @click="$emit('openProfile', element.likes[0].user)" 
          class="font-black text-indigo-900 dark:text-indigo-900" style="z-index: 10; cursor: pointer">
          {{ element.likes[0].user.username }}
        </span>

        {{ $i18n.t("liked_by") }}
      </p>
    </template>

    <template v-else-if="element.likes.length > 1">
      <div class="flex w-full justify-start align-center">
        <div style="min-height: 20px" class="relative"
          :class="element.likes.length == 1 ? 'w-5' : (element.likes.length == 2 ? 'w-8' : 'w-11')">

          <div class="w-5 absolute top-0" style="max-height: 20px; min-width: 20px"
            v-for="(like, index) in element.likes" :key="like.id" :class="[formatLeftImg[index]]"
            :style="{ 'z-index': formatZIndex[index] }">
            <img v-if="index < 3" class=" object-cover rounded-full" :src="
              like.user.imgkit_profile_picture ? like.user.imgkit_profile_picture : '/static/profile-default.jpg'
            " />
          </div>
        </div>

        <div class="ml-2">
          <p class="text-black-500 text-indigo-900 text-sm dark:text-white">
            <span
              @click="$emit('openProfile', id && element.likes[0].user.id === id ? element.likes[1].user : element.likes[0].user)"
              class="font-black text-indigo-900 dark:text-indigo-900" style="z-index: 10; cursor: pointer">
              {{ id && element.likes[0].user.id === id ? element.likes[1].user.username : element.likes[0].user.username }}
            </span>

            <span class="text-black-500 text-sm dark:text-white" @click="$emit('openModal', element.id)"
              style="cursor: pointer">
              {{ " " + $i18n.t("and") + singularValue(element.total_likes < element.likes.length ? element.likes.length : element.total_likes) }}
            </span>

            <span class="text-black-500 text-sm dark:text-white" style="cursor: pointer"
              @click="$emit('openModal', element.id)">
              {{ $i18n.t("liked_by_others") }}
            </span>
          </p>
        </div>
      </div>
    </template>

  </div>
</template>

<script>



export default {
  props: {
    element: {
      type: Object,
      required: true
    },
    id: {
      default: -1,
      type: Number
    }
  },
  data() {
    return {
      formatLeftImg: {
        0: 'left-1',
        1: 'left-3.5',
        2: 'left-6',
      },
      post: {

      },
      formatZIndex: {
        0: 3,
        1: 2,
        2: 1,
      }
    }
  },
  methods: {
    singularValue(val) {
      const isSingular = val - 1 === 1
      if (isSingular) return ` ${val - 1} ${this.$i18n.t("other")}`

      return ` ${val - 1} ${this.$i18n.t("others")}`
    }
  },
}
</script>
