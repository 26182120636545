<template>
  <div>
    <div v-if="canEdit" class="relative">
      <img
        class="object-cover w-full h-full"
        :class="rounded ? 'rounded-full' : ''"
        @click="openStory"
        style="aspect-ratio: 1; cursor: pointer"
        :style="pictureBorder"
        :src="profilePicture ? profilePicture : '/static/profile-default.jpg'"
        alt="Profile picture"
      />
      <icon
        @click="showModal = true"
        style="width: 26px; height: 26px; cursor: pointer"
        icon="plus"
        class="rounded-full bg-primary-700 text-white text-3xl absolute bottom-0 right-2 p-2"
      />
    </div>

    <div v-else class="relative">
      <img
        class="object-cover w-full h-full"
        :class="rounded ? 'rounded-full' : ''"
        @click="canView ? openStory() : null"
        style="aspect-ratio: 1"
        :style="pictureBorder"
        :src="
          profilePicture && !imgError
            ? profilePicture
            : '/static/profile-default.jpg'
        "
        alt="Profile picture"
        @error="handleImgError"
      />
      <template v-if="connectionStatus !== -1">
        <div
          v-if="connectionStatus === 1"
          class="absolute top-0 left-0 w-3 h-3 bg-green-600 rounded-full"
        ></div>
        <div
          v-if="connectionStatus === 0"
          class="absolute top-0 left-0 w-3 h-3 bg-red-700 rounded-full"
        ></div>
      </template>
    </div>

    <template v-if="canEdit">
      <t-modal
        :header="$i18n.t('change_profile_img')"
        :value="showModal"
        @closed="onClosed"
        variant="xl"
        :clickToClose="false"
        @before-close="beforeClose"
      >
        <t-upload-file
          v-show="!base64Img"
          class="sm:w-2/5 mx-auto"
          :file.sync="profile_picture"
          :base64.sync="base64Img"
          :loading.sync="isLoading"
          ref="profileImg"
        />

        <cropper
          class="cropper"
          :src="base64Img"
          :stencil-props="{
            aspectRatio: 1
          }"
          @change="change"
        />

        <template v-slot:footer>
          <div class="flex justify-end">
            <t-button
              :disabled="!base64Img"
              variant="outline-gray-sm"
              type="button"
              @click="resetPicture"
              class="mx-1"
              >{{ $i18n.t("reset") }}</t-button
            >
            <t-button
              variant="outline-gray-sm"
              type="button"
              @click="showModal = false"
              class="mx-1"
              >{{ $i18n.t("cancel") }}</t-button
            >

            <t-button
              type="button"
              :disabled="isLoading"
              @click="updatePicture"
              class="mx-1"
              >{{ $i18n.t("save") }}
              <span v-if="isLoading">
                <t-loader extraClass="ml-1 border-orange-primary my-auto"
              /></span>
            </t-button>
          </div>
        </template>
      </t-modal>
    </template>
  </div>
</template>

<script>
import { Cropper } from "vue-advanced-cropper"
import "vue-advanced-cropper/dist/style.css"
const notify = () => import("@/utils/notify.js")

export default {
  components: {
    Cropper
  },
  props: {
    data: {
      type: Object,
      default: {}
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    canView: {
      type: Boolean,
      default: true
    },
    rounded: {
      type: Boolean,
      default: true
    },
    connectionStatus: {
      type: Number,
      default: -1
    },
    sizePx: {
      type: Number,
      default: 64
    },
    storyBorder: {
      type: Boolean,
      default: false
    },
    fieldName: {
      type: String,
      default: "profile_picture"
    }
  },
  computed: {
    pictureBorder() {
      if (this.storyBorder && this.canView) {
        let index = this.userStories.findIndex((x) => x.id === this.data.id)
        if (index !== -1) {
          if (this.userStories[index].stories_viewed)
            return "border: 2px solid #A0A0A0; cursor: pointer;"
        } else if (this.data.stories_viewed)
          return "border: 2px solid #A0A0A0; cursor: pointer;"

        return "border: 2px solid #6a71ec; cursor: pointer"
      } else {
        return ""
      }
    },
    profilePicture() {
      if (this.data["imgkit_" + this.fieldName]) {
        return (
          this.data["imgkit_" + this.fieldName] +
          `?tr=w-${this.sizePx},h-${this.sizePx}`
        )
      } else return this.data[this.fieldName]
    },
    userStories() {
      return this.$store.getters["story/getAllUnpaginated"]
    },
    allUserStories() {
      return this.$store.getters["story/getItem"]
    }
  },
  data() {
    return {
      showModal: false,
      profile_picture: null,
      base64Img: null,
      isLoading: false,
      imgError: false
    }
  },
  methods: {
    getUserStories() {

      let actual_user = this.data;
      let route;
      if(actual_user.private == 0){
        route = "/story/all";
      }else{
        route = "/story/all";
      }
      this.$store
        .dispatch("global/getItems", {
          route: route,
          noPaginate: true,
          params: {
            id_user: this.data.id,
            order_key: "created_at",
            order_value: "asc",
            active: 1
          }
        })
        .then((response) => {
          const datax = {
            stories: response.data,
            stories_viewed: response.data.every((x) => x.viewed === 1) ? 1 : 0,
            ...this.data
          }
          this.$store.dispatch("story/setItems", [datax])
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    openStory() {
      if (this.data.stories_available === 1) {
        this.$emit("openStory", this.userStories[0])
      }
    },
    handleImgError() {
      this.imgError = true
    },
    resetPicture() {
      this.profile_picture = null
      this.base64Img = null
      this.$refs.profileImg.image = null
      this.$refs.profileImg.$refs.file = null
    },
    beforeClose() {
      if (this.$refs.profileImg) {
        this.$refs.profileImg.image = null
        this.$refs.profileImg.$refs.file = null
      }

      this.profile_picture = null
      this.base64Img = null
    },
    onClosed() {
      this.showModal = false
    },
    change({ canvas }) {
      canvas.toBlob((blob) => {
        const file = new File([blob], "image.png")
        this.profile_picture = file
      })
    },
    updatePicture() {
      this.isLoading = true
      this.$store
        .dispatch("global/update", {
          payload: {
            id: this.data.id,
            profile_picture: this.profile_picture
          },
          route: "/user/update",
          module: "user",
          isCurrentUser: true
        })
        .then((response) => {
          if ([200, 201].includes(response.code)) {
            this.showModal = false
            this.$emit("userUpdate", response.data)
          }

          notify().then(({ notification }) => {
            notification(
              "master-update",
              response.code,
              this.$snotify,
              this.$i18n.locale
            )
          })
          this.isLoading = false
        })
        .catch((error) => {
          this.isLoading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    }
  },
  created() {},
  watch: {
    data() {
      if (this.$route.name === "User Profile" || this.$route.name === "Profile")
        this.getUserStories()
    }
  }
}
</script>

<style lang="scss">
.cropper {
  max-height: 70vh;
}
</style>
